"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RevokeAccessButton = void 0;
const modals_1 = require("@ui/modals");
const sonar_core_1 = require("@sonar-software/sonar-core");
const popups_1 = require("@lib/popups");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const RevokeAccessButton = ({ applicationId }) => {
    const subdomain = (0, _hooks_1.useCurrentOrganizationSubdomain)();
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const hideApplication = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreDataSecurityApplications);
    const handleHide = (0, react_1.useCallback)(() => {
        hideApplication({ applicationId });
        window.open(sonar_ts_constants_1.LINKS.SALESFORCE_MANAGE_APPS(subdomain), '_blank');
    }, [applicationId, hideApplication, subdomain]);
    if (isReadOnly) {
        return null;
    }
    return (react_1.default.createElement(modals_1.ConfirmDeleteButton, { buttonId: 'revoke-app-button', buttonLabel: sonar_ts_constants_1.COPY_SPECIFIC.REVOKE_APP_ACCESS, cancelButtonProps: {
            value: sonar_ts_constants_1.COPY_SPECIFIC.NO
        }, className: 'confirm-hide', confirmButtonProps: {
            value: sonar_ts_constants_1.COPY_SPECIFIC.YES
        }, getPopupContainer: popups_1.getClosestCardHeader, onCancel: () => window.open(sonar_ts_constants_1.LINKS.SALESFORCE_MANAGE_APPS(subdomain), '_blank'), onConfirm: handleHide, title: sonar_ts_constants_1.MESSAGES_COPY.HIDE_AS_WELL_INTEGRATION_APP, type: 'link' }));
};
exports.RevokeAccessButton = RevokeAccessButton;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeTabArea = exports.PeriscopeMenuTabs = void 0;
require("./PeriscopeMenuTabs.scss");
const icons_1 = require("@ant-design/icons");
const PeriscopeMenuLabel_1 = require("./PeriscopeMenuLabel");
const sonar_http_1 = require("@sonar-software/sonar-http");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const _hooks_1 = require("@hooks");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
function initializeTabCounts(children, setTabCounts) {
    const result = {};
    for (let i = 0; i < children.length; i += 1) {
        const { key, props } = children[i];
        const { count, countEndpoint } = props;
        const stringKey = String(key);
        if (!(0, sonar_ts_lib_1.isUndefined)(count) || (0, sonar_ts_lib_1.isUndefined)(countEndpoint)) {
            result[stringKey] = count;
        }
        else if (countEndpoint) {
            result[stringKey] = (0, sonar_http_1.sonarHttpGet)({
                targetUri: countEndpoint
            }).then((response) => {
                var _a;
                const value = (_a = response.data) !== null && _a !== void 0 ? _a : 0;
                setTabCounts((counts) => (Object.assign(Object.assign({}, counts), { [stringKey]: value })));
                return value;
            });
        }
    }
    return result;
}
function updateCountLabels(menuItem) {
    var _a;
    const submenu = menuItem;
    if (!((_a = submenu.children) === null || _a === void 0 ? void 0 : _a.length)) {
        return;
    }
    for (const child of submenu.children) {
        updateCountLabels(child);
    }
    const countChildren = submenu.children.filter((child) => !(0, sonar_ts_lib_1.isUndefined)(child.count));
    if (!countChildren.length) {
        return;
    }
    const sumPromise = Promise.all(countChildren.map((child) => { var _a; return Promise.resolve((_a = child.count) !== null && _a !== void 0 ? _a : 0); })).then((values) => values.reduce((prev, curr) => (prev !== null && prev !== void 0 ? prev : 0) + (curr !== null && curr !== void 0 ? curr : 0), 0));
    submenu.count = sumPromise;
    submenu.label = react_1.default.createElement(PeriscopeMenuLabel_1.PeriscopeMenuLabel, { count: sumPromise, name: submenu.label });
}
function getEnabledChildren(children, isEnabled) {
    return react_1.default.Children.toArray(children).filter((child) => {
        if (child.props.hidden) {
            return false;
        }
        if (!child.props.featureFlag) {
            return true;
        }
        return isEnabled(child.props.featureFlag);
    });
}
function buildMenuItems(children, setOpenKeys, activeKeyPathRef, tabCounts) {
    var _a;
    const results = [];
    const submenuMap = {};
    const groupMap = {};
    const onSubmenuClick = (info) => {
        if (info.key === activeKeyPathRef.current[0] && activeKeyPathRef.current.length > 1) {
            setTimeout(() => {
                setOpenKeys(activeKeyPathRef.current);
            }, 150);
        }
    };
    const onSubmenuGroupMouseEnter = (info) => {
        setOpenKeys((keys) => {
            if (keys.indexOf(info.key) === -1) {
                return [...keys, info.key];
            }
            return keys;
        });
    };
    const onSubmenuGroupMouseLeave = (info) => {
        setOpenKeys((keys) => {
            const index = keys.indexOf(info.key);
            if (index !== -1) {
                return [...keys.slice(0, index), ...keys.slice(index + 1)];
            }
            return keys;
        });
    };
    for (let i = 0; i < children.length; i += 1) {
        const { key, props } = children[i];
        const { disabled, group, requiresCount, submenu, tab } = props;
        let targetSubmenu = undefined;
        let targetGroup = undefined;
        if (submenu) {
            targetSubmenu = (0, sonar_ts_lib_1.getOrAddEntry)(submenu, submenuMap, () => {
                const newSubmenu = {
                    children: [],
                    key: submenu,
                    label: submenu,
                    onTitleClick: onSubmenuClick,
                    popupClassName: 'periscope-tab-submenu',
                    popupOffset: [-10, 0]
                };
                results.push(newSubmenu);
                return newSubmenu;
            });
        }
        if (group) {
            targetGroup = (0, sonar_ts_lib_1.getOrAddEntry)(group, groupMap, () => {
                const newGroup = {
                    children: [],
                    key: group,
                    label: group,
                    onMouseEnter: onSubmenuGroupMouseEnter,
                    onMouseLeave: onSubmenuGroupMouseLeave,
                    popupOffset: [0, -4]
                };
                if (targetSubmenu) {
                    targetSubmenu.children.push(newGroup);
                }
                else {
                    results.push(newGroup);
                }
                return newGroup;
            });
        }
        if (targetSubmenu && targetGroup && !targetSubmenu.children.includes(targetGroup)) {
            targetSubmenu.children.push(targetGroup);
        }
        const count = tabCounts[String(key)];
        const isDisabled = disabled || (requiresCount && (!count || isNaN(Number(count))));
        const entry = {
            count,
            disabled: isDisabled,
            key: key,
            label: react_1.default.createElement(PeriscopeMenuLabel_1.PeriscopeMenuLabel, { count: count, name: tab })
        };
        if (targetGroup) {
            (_a = targetGroup.children) === null || _a === void 0 ? void 0 : _a.push(entry);
        }
        else if (!targetGroup && targetSubmenu) {
            targetSubmenu.children.push(entry);
        }
        else {
            results.push(entry);
        }
    }
    for (const entry of results) {
        updateCountLabels(entry);
    }
    return results;
}
function buildTabItems(children) {
    return children.map(({ key, props }) => ({
        children: props.children,
        forceRender: props.forceRender,
        key: String(key),
        label: String(key)
    }));
}
const PeriscopeMenuTabs = ({ children, defaultKey }) => {
    const { isEnabled } = (0, _hooks_1.useFeatureFlags)();
    const [tabItems, setTabItems] = (0, react_1.useState)([]);
    const [activeKey, setActiveKey] = (0, react_1.useState)(defaultKey ? `.$${defaultKey}` : undefined);
    const [openKeys, setOpenKeys] = (0, react_1.useState)([]);
    const [tabCounts, setTabCounts] = (0, react_1.useState)();
    const availableChildrenRef = (0, react_1.useRef)(getEnabledChildren(children, isEnabled));
    const activeKeyPathRef = (0, react_1.useRef)([]);
    (0, _hooks_1.useOnMount)(() => {
        setTabCounts(initializeTabCounts(availableChildrenRef.current, setTabCounts));
        setTabItems(buildTabItems(availableChildrenRef.current));
    });
    const menuItems = (0, react_1.useMemo)(() => (tabCounts ? buildMenuItems(availableChildrenRef.current, setOpenKeys, activeKeyPathRef, tabCounts) : []), [tabCounts]);
    return (react_1.default.createElement(antd_1.Tabs, { activeKey: activeKey, className: 'periscope-tab', items: tabItems, onChange: setActiveKey, renderTabBar: (props) => react_1.default.createElement("div", { className: 'ant-tabs-nav' },
            react_1.default.createElement(antd_1.Menu, { activeKey: props.activeKey, disabledOverflow: true, expandIcon: react_1.default.createElement(icons_1.ArrowRightOutlined, null), items: menuItems, mode: 'horizontal', onClick: (clickEvent) => {
                    props.onTabClick(clickEvent.key, clickEvent.domEvent);
                    activeKeyPathRef.current = clickEvent.keyPath.slice(1).reverse();
                }, onOpenChange: (keys) => setOpenKeys(keys), openKeys: openKeys, triggerSubMenuAction: 'click' })) }));
};
exports.PeriscopeMenuTabs = PeriscopeMenuTabs;
const PeriscopeTabArea = () => null;
exports.PeriscopeTabArea = PeriscopeTabArea;

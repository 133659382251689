"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupFilter = void 0;
const filters_1 = require("@ui/table-modifier/filters");
const popups_1 = require("@lib/popups");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const filters_2 = require("@periscopes/general/filters");
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const GroupFilter = (props) => {
    const { format, id, onChange, value, label } = props;
    const stakeholders = (0, _hooks_1.useStakeholders)();
    const options = (0, react_1.useMemo)(() => {
        const stakeholderMap = stakeholders
            .filter((stakeholder) => stakeholder.stakeholderType === sonar_core_1.StakeholderType.Team)
            .map((stakeholder) => {
            const { name, stakeholderId } = stakeholder;
            return {
                label: name,
                value: stakeholderId
            };
        });
        stakeholderMap.unshift({
            label: sonar_ts_constants_1.COPY_SPECIFIC.NO_GROUPS,
            value: -1
        });
        return stakeholderMap;
    }, [stakeholders]);
    return (react_1.default.createElement(filters_1.FilterComponent, Object.assign({}, props, { tagProps: {
            label,
            value: options
                .filter((option) => value === null || value === void 0 ? void 0 : value.includes(option.value))
                .map((option) => option.label)
                .join(', ')
        } }),
        react_1.default.createElement(filters_2.OptionFilter, { className: 'filter', getPopupContainer: popups_1.getDirectParent, id: (0, lib_1.getFilterId)(id), loading: !options, maxTagCount: 'responsive', mode: 'multiple', onChange: (stakeholderIds) => {
                onChange({
                    [id]: {
                        format,
                        value: stakeholderIds
                    }
                });
            }, options: options !== null && options !== void 0 ? options : [], placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(label), showArrow: true, value: value })));
};
exports.GroupFilter = GroupFilter;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetupAuditTrailRule = void 0;
const lodash_1 = require("lodash");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const icons_1 = require("@ant-design/icons");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const text_1 = require("@ui/text");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_if_1 = require("react-if");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const operators = {
    action: sonar_ts_constants_1.COPY_SPECIFIC.CONTAINS,
    classification: sonar_ts_constants_1.COPY_SPECIFIC.IS_ANY,
    section: sonar_ts_constants_1.COPY_SPECIFIC.EQUALS
};
const sectionsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.setupAuditTrail.sections.get);
const SetupAuditTrailRule = ({ field, canDelete, onDelete, ruleType }) => {
    const { data: sectionData, isRequested } = (0, _hooks_1.useAsyncState)(sectionsSelectors);
    const form = antd_1.Form.useFormInstance();
    const selectedCondition = antd_1.Form.useWatch(['monitorConfig', 'rules', field.name, 'condition']);
    const sectionOptions = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = sectionData === null || sectionData === void 0 ? void 0 : sectionData.map((section) => ({
            label: section,
            value: section
        }))) !== null && _a !== void 0 ? _a : [];
    }, [sectionData]);
    const classificationOptions = (0, react_1.useMemo)(() => (0, sonar_ts_lib_1.enumStringKeys)(sonar_core_1.ClassificationLevel).reverse()
        .map((classification) => ({
        label: classification,
        value: classification
    })), []);
    const options = selectedCondition === 'classification' ? classificationOptions : sectionOptions;
    const getSetupAuditTrailSections = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreSetupAuditTrailSections);
    (0, _hooks_1.useOnMount)(() => {
        getSetupAuditTrailSections();
    });
    const getConditionOptions = (0, react_1.useCallback)(() => {
        const currentRuleConditions = form.getFieldValue(['monitorConfig', 'rules']).reduce((previous, current, index) => {
            if (current && index !== field.name) {
                return [...previous, current.condition];
            }
            return [...previous];
        }, []);
        let conditionOptions = [
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.ACTION,
                value: 'action'
            }
        ];
        if (!(currentRuleConditions === null || currentRuleConditions === void 0 ? void 0 : currentRuleConditions.includes('section'))) {
            conditionOptions = [{
                    label: sonar_ts_constants_1.COPY_SPECIFIC.SECTION,
                    value: 'section'
                }, ...conditionOptions];
        }
        if (!(currentRuleConditions === null || currentRuleConditions === void 0 ? void 0 : currentRuleConditions.includes('classification'))) {
            conditionOptions = [{
                    label: sonar_ts_constants_1.COPY_SPECIFIC.CLASSIFICATION,
                    value: 'classification'
                }, ...conditionOptions];
        }
        return conditionOptions;
    }, [form, field]);
    const initialCondition = (0, react_1.useMemo)(() => {
        const currentRuleConditions = form.getFieldValue(['monitorConfig', 'rules']).reduce((previous, current) => {
            if (current) {
                return [...previous, current.condition];
            }
            return [...previous];
        }, []);
        if (!(currentRuleConditions === null || currentRuleConditions === void 0 ? void 0 : currentRuleConditions.includes('classification'))) {
            return 'classification';
        }
        if (!(currentRuleConditions === null || currentRuleConditions === void 0 ? void 0 : currentRuleConditions.includes('section'))) {
            return 'section';
        }
        return 'action';
    }, [form]);
    const getDefaultValue = (0, react_1.useCallback)((newCondition) => {
        if (newCondition === 'classification') {
            return [sonar_core_1.ClassificationLevel.Critical];
        }
        if (newCondition === 'section') {
            return sectionOptions.length > 0 ? [sectionOptions[0].value] : undefined;
        }
        return [];
    }, [sectionOptions]);
    const onConditionChange = (0, react_1.useCallback)((condition) => {
        form.setFieldValue(['monitorConfig', 'rules', field.name, 'value'], getDefaultValue(condition));
    }, [field, form, getDefaultValue]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", null,
            react_1.default.createElement(react_if_1.When, { condition: field.name !== 0 },
                react_1.default.createElement(text_1.SonarText, null, (0, lodash_1.capitalize)(ruleType)))),
        react_1.default.createElement(antd_1.Form.Item, { initialValue: initialCondition, name: [field.name, 'condition'] },
            react_1.default.createElement(antd_1.Select, { onChange: onConditionChange, options: getConditionOptions() })),
        react_1.default.createElement("div", null,
            react_1.default.createElement(text_1.SonarText, null, selectedCondition ? operators[selectedCondition] : '')),
        react_1.default.createElement(react_if_1.If, { condition: selectedCondition === 'action' },
            react_1.default.createElement(react_if_1.Then, null,
                react_1.default.createElement(antd_1.Form.Item, { name: [field.name, 'value', 0] },
                    react_1.default.createElement(antd_1.Input, null))),
            react_1.default.createElement(react_if_1.Else, null,
                react_1.default.createElement(antd_1.Form.Item, { initialValue: getDefaultValue(initialCondition), name: [field.name, 'value'] },
                    react_1.default.createElement(antd_1.Select, { loading: isRequested, mode: 'multiple', options: options, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.NO_SECTIONS_AVAILABLE })))),
        react_1.default.createElement("div", null,
            react_1.default.createElement(react_if_1.When, { condition: canDelete },
                react_1.default.createElement(antd_1.Button, { className: 'delete-rule-button', icon: react_1.default.createElement(icons_1.DeleteOutlined, null), onClick: onDelete, style: { alignSelf: 'flex-start' } })))));
};
exports.SetupAuditTrailRule = SetupAuditTrailRule;

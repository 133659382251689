"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetupAuditTrailViewArea = void 0;
require("./SetupAuditTrailView.scss");
const notifications_1 = require("@ui/notifications");
const MonitorModal_1 = require("@security/monitors/MonitorModal");
const selectors_1 = require("@lib/selectors");
const MonitorMenu_1 = require("@security/monitors/monitor-form/MonitorMenu");
const SetupAuditTrailGraph_1 = require("./SetupAuditTrailGraph");
const SetupAuditTrailTable_1 = require("./SetupAuditTrailTable");
const text_1 = require("@ui/text");
const _hooks_1 = require("@hooks");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const DateRangePicker_1 = require("@ui/DateRangePicker");
const react_1 = __importStar(require("react"));
const general_1 = require("@ui/general");
const postMonitorSelectors = (0, selectors_1.createSonarSelector)((state) => state.core.dataSecurity.monitors.post);
const SetupAuditTrailViewArea = () => {
    const [dateRange, setDateRange] = (0, react_1.useState)({ start: (0, sonar_ts_lib_1.getPastDate)(30) });
    const [classificationsFilter, setClassificationsFilter] = (0, react_1.useState)([]);
    const [showModal, setShowModal] = (0, _hooks_1.useBoolean)(false);
    const lastUpdated = (0, sonar_ts_lib_1.convertISODateToShortDate)(new Date().toISOString());
    const satMonitor = MonitorMenu_1.monitorMenuConfigs.find((x) => x.type === sonar_core_1.DataSecurityMonitorType.SetupAuditTrail);
    return (react_1.default.createElement(general_1.ViewArea, { collapseSidebar: true, direction: 'horizontal', fullWidth: true, id: 'setup-audit-trail-view' },
        react_1.default.createElement("div", { className: 'setup-audit-trail-view-content' },
            react_1.default.createElement(general_1.SonarCard, { className: 'setup-audit-trail-view-header', extra: react_1.default.createElement(antd_1.Space, { size: 'middle' },
                    react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_GENERIC.LAST_UPDATED(lastUpdated)),
                    react_1.default.createElement(DateRangePicker_1.DateRangePicker, { endDate: dateRange.end, onChange: setDateRange, startDate: dateRange.start }),
                    react_1.default.createElement(antd_1.Button, { className: 'create-monitor-button', onClick: setShowModal.on, type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.CREATE_MONITOR)), title: sonar_ts_constants_1.COPY_SPECIFIC.SETUP_AUDIT_TRAIL },
                react_1.default.createElement(react_if_1.When, { condition: satMonitor === null || satMonitor === void 0 ? void 0 : satMonitor.active },
                    react_1.default.createElement(MonitorModal_1.CreateMonitorModal, { menuOptions: 'hide', monitor: satMonitor === null || satMonitor === void 0 ? void 0 : satMonitor.default, onCancel: setShowModal.off, onSave: setShowModal.off, open: showModal, title: sonar_ts_constants_1.COPY_SPECIFIC.MONITOR_BUILDER }))),
            react_1.default.createElement(SetupAuditTrailGraph_1.SetupAuditTrailGraph, { classificationsFilter: classificationsFilter, dateRange: dateRange, onClassificationFilterChange: setClassificationsFilter }),
            react_1.default.createElement(SetupAuditTrailTable_1.SetupAuditTrailTable, { classificationsFilter: classificationsFilter, dateRange: dateRange, onClassificationFilterChange: setClassificationsFilter }),
            react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.SETTINGS_DATA_SECURITY_COPY.MONITOR_SAVED, resetRawAction: sonar_core_1.coreActions.resetPostCoreDataSecurityMonitors, stateSelector: postMonitorSelectors }))));
};
exports.SetupAuditTrailViewArea = SetupAuditTrailViewArea;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentLogTable = void 0;
require("./IncidentLogTable.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const IncidentLogTableColumns_1 = require("./IncidentLogTableColumns");
const IncidentLogExportButton_1 = require("./IncidentLogExportButton");
const general_1 = require("@ui/general");
const react_router_1 = require("react-router");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const tables_1 = require("@ui/tables");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const dataSecurityIncidentLogSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.incidentLog.get);
function getRowKey(entry) {
    return `${entry.applicationId}-${entry.eventStart}`;
}
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    filterParams: {
        applicationId: true,
        stakeholderGroupId: true,
        start: {
            name: 'lastDetectedDate',
            rangeEnd: 'end'
        }
    }
});
const IncidentLogTable = ({ applicationId, viewParams }) => {
    const navigate = (0, react_router_1.useNavigate)();
    const allowOverview = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataSecurityDashboard);
    const getDataSecurityIncidentLog = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityIncidentLog);
    const [dateChanged, setDateChanged] = (0, react_1.useState)(false);
    const previousApplicationId = (0, _hooks_1.usePrevious)(applicationId);
    const applicationIdChanged = applicationId !== previousApplicationId;
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(dataSecurityIncidentLogSelectors);
    const columns = (0, react_1.useMemo)(() => (0, IncidentLogTableColumns_1.getColumns)(!allowOverview || Boolean(applicationId), navigate), [allowOverview, applicationId, navigate]);
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            applicationId,
            count: 10,
            sortBy: 'detectedDate',
            sortDirection: 1,
            start: (0, sonar_ts_lib_1.getPastDate)(7)
        },
        onUpdate: (_newParams, _prev, combined) => {
            getDataSecurityIncidentLog(convertToDql(combined));
        }
    });
    (0, _hooks_1.useOnMount)(() => {
        getDataSecurityIncidentLog(convertToDql(queryParams));
    });
    (0, react_1.useEffect)(() => {
        if (!applicationId && viewParams.appGroup !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.stakehoderGroupId)) {
            updateParams({ stakehoderGroupId: viewParams.appGroup });
        }
    }, [applicationId, queryParams === null || queryParams === void 0 ? void 0 : queryParams.stakehoderGroupId, updateParams, viewParams.appGroup]);
    (0, react_1.useEffect)(() => {
        if (viewParams.dateRange.end !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.end) || viewParams.dateRange.start !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.start)) {
            setDateChanged(true);
            const { start, end } = viewParams.dateRange;
            updateParams({
                end: end === null ? String(Date.now()) : end,
                start
            });
        }
        else if (dateChanged) {
            setDateChanged(false);
        }
    }, [dateChanged, viewParams, queryParams === null || queryParams === void 0 ? void 0 : queryParams.end, queryParams === null || queryParams === void 0 ? void 0 : queryParams.start, updateParams]);
    return (react_1.default.createElement(general_1.SonarCard, { className: 'incident-log-table', extra: react_1.default.createElement(IncidentLogExportButton_1.IncidentLogExportButton, { queryParams: convertToDql(queryParams) }), loading: !data || dateChanged || applicationIdChanged, noSpacing: true, title: sonar_ts_constants_1.COPY_SPECIFIC.INCIDENT_LOG, titleClassName: 'h2', useSpinnerLoading: true },
        react_1.default.createElement("div", { className: 'table-container' },
            react_1.default.createElement(tables_1.PaginatedTable, { autohidePagination: true, columns: columns, defaultPageSize: 10, id: 'access-changes-table', loading: isRequested, onChange: updateParams, resource: data, rowKey: getRowKey, showSizeChanger: false, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection }))));
};
exports.IncidentLogTable = IncidentLogTable;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiVolumeGraph = exports.getDataSecurityApiVolumeSelector = void 0;
require("./ApiVolumeGraph.scss");
const plots_1 = require("@ant-design/plots");
const antd_1 = require("antd");
const selectors_1 = require("@lib/selectors");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const react_router_1 = require("react-router");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getCallsText = (calls) => `${calls === null || calls === void 0 ? void 0 : calls.toLocaleString()} ${sonar_ts_constants_1.DATA_SECURITY_DASHBOARD.CALLS}`;
const getAppDisplayName = (id, app = {}) => {
    const { name, label, deletedOn } = app;
    const nameDisplay = id === 0 ? sonar_ts_constants_1.COPY_SPECIFIC.ALL_OTHERS : name !== null && name !== void 0 ? name : id;
    const labelDisplay = label ? ` (${label})` : '';
    const deletedDisplay = deletedOn ? ` - ${sonar_ts_constants_1.COPY_SPECIFIC.HIDDEN}` : '';
    return `${nameDisplay}${labelDisplay}${deletedDisplay}-${id}`;
};
const withoutId = (appName) => appName.replace(/-\w+$/u, '');
const config = {
    fields: ['total'],
    height: 358,
    legend: {
        itemName: { formatter: withoutId },
        itemSpacing: 4
    },
    seriesField: 'appName',
    tooltip: {
        formatter: (item) => ({
            name: withoutId(item.appName),
            value: getCallsText(item.total)
        }),
        title: (title) => (0, sonar_ts_lib_1.getDate)(title).format('ll')
    },
    xAxis: {
        label: {
            formatter: (text) => (0, sonar_ts_lib_1.getDate)(text).format('MMM D'),
            offsetX: -25,
            rotate: -45
        },
        title: {
            autoRotate: false,
            text: sonar_ts_constants_1.COPY_SPECIFIC.DATE
        }
    },
    xField: 'date',
    yAxis: {
        label: {
            formatter: (text) => Number(text).toLocaleString()
        },
        title: {
            text: sonar_ts_constants_1.DATA_SECURITY_DASHBOARD.API_CALLS
        }
    },
    yField: 'total'
};
exports.getDataSecurityApiVolumeSelector = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.apiVolume.get);
exports.ApiVolumeGraph = (0, react_1.memo)(({ applicationId, viewParams, showExploreButton }) => {
    const allowOverview = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataSecurityDashboard);
    const getDataSecurityApiVolume = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityApiVolume);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(exports.getDataSecurityApiVolumeSelector);
    const previousDateRange = (0, _hooks_1.usePrevious)(viewParams.dateRange);
    const previousAppGroup = (0, _hooks_1.usePrevious)(viewParams.appGroup);
    const navigate = (0, react_router_1.useNavigate)();
    const apps = (0, _hooks_1.useApplicationReferences)();
    const appIds = data === null || data === void 0 ? void 0 : data.applications.map(({ id }) => id);
    const appColors = (0, _hooks_1.useDataSecurityAppColors)(appIds);
    const colorsArray = (0, react_1.useMemo)(() => appIds === null || appIds === void 0 ? void 0 : appIds.map((id) => { var _a; return (_a = appColors[id]) !== null && _a !== void 0 ? _a : 'gray'; }), [appColors, appIds]);
    const graphData = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = data === null || data === void 0 ? void 0 : data.applications) === null || _a === void 0 ? void 0 : _a.reduce((allData, app) => {
            var _a, _b;
            return [
                ...allData,
                ...(_b = (_a = app.volumes) === null || _a === void 0 ? void 0 : _a.map((volume) => (Object.assign(Object.assign({}, volume), { appName: getAppDisplayName(app.id, apps[app.id]) })))) !== null && _b !== void 0 ? _b : []
            ];
        }, []);
    }, [apps, data === null || data === void 0 ? void 0 : data.applications]);
    (0, react_1.useEffect)(() => {
        if (viewParams.dateRange.end !== (previousDateRange === null || previousDateRange === void 0 ? void 0 : previousDateRange.end) ||
            viewParams.dateRange.start !== (previousDateRange === null || previousDateRange === void 0 ? void 0 : previousDateRange.start) ||
            (!applicationId && viewParams.appGroup !== previousAppGroup)) {
            getDataSecurityApiVolume({
                applicationId,
                end: viewParams === null || viewParams === void 0 ? void 0 : viewParams.dateRange.end,
                stakeholderGroupId: viewParams === null || viewParams === void 0 ? void 0 : viewParams.appGroup,
                start: viewParams === null || viewParams === void 0 ? void 0 : viewParams.dateRange.start
            });
        }
    }, [applicationId, viewParams, getDataSecurityApiVolume, previousDateRange, previousAppGroup]);
    return (react_1.default.createElement(general_1.SonarCard, { className: 'api-volume-card', extra: allowOverview && showExploreButton &&
            react_1.default.createElement(antd_1.Button, { id: 'explore-button', onClick: () => navigate(sonar_ts_constants_1.FULL_ROUTES.DATA_SECURITY_API_VOLUME) }, sonar_ts_constants_1.COPY_SPECIFIC.EXPLORE), loading: isRequested, noSpacing: true, title: sonar_ts_constants_1.DATA_SECURITY_DASHBOARD.API_VOLUME, titleClassName: 'h2', useSpinnerLoading: true },
        react_1.default.createElement(plots_1.Area, Object.assign({ className: 'api-volume-graph', color: colorsArray, data: graphData !== null && graphData !== void 0 ? graphData : [] }, config))));
});

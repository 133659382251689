"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSecurityAppName = void 0;
var DataSecurityAppName;
(function (DataSecurityAppName) {
    DataSecurityAppName["ActOn"] = "ActOn";
    DataSecurityAppName["AddressTools"] = "AddressTools";
    DataSecurityAppName["AdobeSign"] = "Adobe Sign";
    DataSecurityAppName["Adroll"] = "Adroll";
    DataSecurityAppName["AdWords"] = "AdWords Conversion Import";
    DataSecurityAppName["Aeon2"] = "Aeon2";
    DataSecurityAppName["AgileVizArt"] = "AgileVizArt";
    DataSecurityAppName["AgileVizArtChatter"] = "AgileVizArt Chatter";
    DataSecurityAppName["Altify"] = "Altify";
    DataSecurityAppName["AmazonAppFlow"] = "Amazon AppFlow Embedded Login App";
    DataSecurityAppName["Ambition"] = "Ambition";
    DataSecurityAppName["Apollo"] = "Apollo";
    DataSecurityAppName["ArticleQuality"] = "Article Quality";
    DataSecurityAppName["AscendixSearch"] = "Ascendix Search";
    DataSecurityAppName["AskNicely"] = "AskNicely";
    DataSecurityAppName["Avature"] = "Avature";
    DataSecurityAppName["AzureDataFactory"] = "Azure DataFactory";
    DataSecurityAppName["AzurePurview"] = "Azure Purview";
    DataSecurityAppName["Beynd"] = "Beynd";
    DataSecurityAppName["Bizible"] = "Bizible";
    DataSecurityAppName["Blackthorn"] = "Blackthorn | Connected App";
    DataSecurityAppName["Brainshark"] = "Brainshark";
    DataSecurityAppName["BulkObjectCreator"] = "Bulk Object Creator";
    DataSecurityAppName["Calendly"] = "Calendly";
    DataSecurityAppName["CarrierDownloads"] = "Carrier Downloads";
    DataSecurityAppName["Celigo"] = "Celigo";
    DataSecurityAppName["Celigo2"] = "Celigo 2";
    DataSecurityAppName["Celoxis"] = "Celoxis";
    DataSecurityAppName["Certain"] = "Certain";
    DataSecurityAppName["ChiliPiper"] = "Chili Piper - Sales Acceleration 3.0";
    DataSecurityAppName["ChurnZero"] = "Churn Zero";
    DataSecurityAppName["Clari"] = "Clari Copilot";
    DataSecurityAppName["Clearbit"] = "Clearbit";
    DataSecurityAppName["ClientSuccess"] = "ClientSuccess";
    DataSecurityAppName["ClosePlan"] = "ClosePlan";
    DataSecurityAppName["CloudingoAgent"] = "Cloudingo Agent";
    DataSecurityAppName["Coefficient"] = "Coefficient";
    DataSecurityAppName["Cognisim"] = "Cognism Chrome";
    DataSecurityAppName["Collibra"] = "Collibra";
    DataSecurityAppName["CommVault"] = "CommVault";
    DataSecurityAppName["Concur"] = "Concur";
    DataSecurityAppName["ConcurConnector"] = "Concur Connector (US2)";
    DataSecurityAppName["ConfigWorkbookTool"] = "Config Workbook Tool";
    DataSecurityAppName["CongaApprovals"] = "Conga Approvals";
    DataSecurityAppName["ConnectAndSell"] = "ConnectAndSell";
    DataSecurityAppName["ContactClean"] = "Contact Clean";
    DataSecurityAppName["Copado"] = "Copado";
    DataSecurityAppName["CopadoDevOps"] = "Copado DevOps (N.A.)";
    DataSecurityAppName["Correlated"] = "Correlated Labs Salesforce Connection";
    DataSecurityAppName["CPOMS"] = "CPOMS";
    DataSecurityAppName["Crayon"] = "Crayon";
    DataSecurityAppName["Crossbeam"] = "Crossbeam";
    DataSecurityAppName["CrossbeamSalesforce"] = "Crossbeam Salesforce App";
    DataSecurityAppName["Crowdvocate"] = "Crowdvocate";
    DataSecurityAppName["CTUCloneThisUser"] = "CTU: Clone This User";
    DataSecurityAppName["Cvent"] = "Cvent";
    DataSecurityAppName["DataAnalytics"] = "Data Analytics";
    DataSecurityAppName["DataChef"] = "Data Chef";
    DataSecurityAppName["DataDotCom"] = "Data.com";
    DataSecurityAppName["DataFox"] = "DataFox";
    DataSecurityAppName["DataLoader"] = "Data Loader";
    DataSecurityAppName["DataLoaderPartner"] = "Dataloader Partner";
    DataSecurityAppName["DataLoaderIO"] = "dataloader.io_v2";
    DataSecurityAppName["DataMask"] = "Data Mask";
    DataSecurityAppName["Dealhub"] = "Dealhub";
    DataSecurityAppName["DefHC"] = "DefHC";
    DataSecurityAppName["Delighted"] = "Delighted";
    DataSecurityAppName["Demandbase"] = "Demandbase";
    DataSecurityAppName["DeskDotCom"] = "Desk.com";
    DataSecurityAppName["Dialpad"] = "Dialpad";
    DataSecurityAppName["DiscoverOrg"] = "DiscoverOrg";
    DataSecurityAppName["DiscoveryDataLink"] = "Discovery DataLink";
    DataSecurityAppName["DistrEngineNC2"] = "Distr. Engine - NC2";
    DataSecurityAppName["Docusign"] = "Docusign";
    DataSecurityAppName["DocusignConnect"] = "DocuSign/Connect/";
    DataSecurityAppName["Dooly"] = "Dooly";
    DataSecurityAppName["Drift"] = "Drift";
    DataSecurityAppName["DunAndBradstreet"] = "Dun & Bradstreet";
    DataSecurityAppName["DuplicateCheck"] = "Duplicate Check";
    DataSecurityAppName["EditQuotas"] = "Edit Quotas";
    DataSecurityAppName["enosiX"] = "enosiX";
    DataSecurityAppName["EnosixSDK"] = "Enosix SDK";
    DataSecurityAppName["enosixSurface"] = "enosix Surface";
    DataSecurityAppName["FieldFootprint"] = "Field Footprint";
    DataSecurityAppName["FinancialForce"] = "FinancialForce";
    DataSecurityAppName["FinancialForceERP"] = "FinancialForce ERP";
    DataSecurityAppName["FinancialForceInv"] = "FinancialForce Inv.";
    DataSecurityAppName["Five9"] = "Five9";
    DataSecurityAppName["Fivetran"] = "Fivetran Data Loader";
    DataSecurityAppName["FlowActionsBasePack"] = "FlowActionsBasePack";
    DataSecurityAppName["FluxCapacity"] = "Flux Capacity";
    DataSecurityAppName["Folderize"] = "Folderize";
    DataSecurityAppName["FormAssembly"] = "FormAssembly";
    DataSecurityAppName["FullCircle"] = "Full Circle";
    DataSecurityAppName["G2Crowd"] = "G2Crowd";
    DataSecurityAppName["Gainsight"] = "Gainsight";
    DataSecurityAppName["GainsightApp"] = "Gainsight App";
    DataSecurityAppName["Genesys"] = "Genesys";
    DataSecurityAppName["Geopointe"] = "Geopointe";
    DataSecurityAppName["Gong"] = "Gong";
    DataSecurityAppName["GongIntegration"] = "Gong Integration App";
    DataSecurityAppName["GridBuddy"] = "GridBuddy";
    DataSecurityAppName["Groove"] = "Groove";
    DataSecurityAppName["Heap"] = "Heap";
    DataSecurityAppName["HelloSign"] = "HelloSign";
    DataSecurityAppName["HGData"] = "HG Data";
    DataSecurityAppName["Highspot"] = "Highspot";
    DataSecurityAppName["Hightouch"] = "Hightouch";
    DataSecurityAppName["Hubspot"] = "Hubspot";
    DataSecurityAppName["Impartner"] = "Impartner";
    DataSecurityAppName["ImpartnerMDF"] = "Impartner MDF";
    DataSecurityAppName["InMoment"] = "InMoment";
    DataSecurityAppName["IntacctAdvanced"] = "Intacct Advanced";
    DataSecurityAppName["IntacctContract"] = "Intacct Contract";
    DataSecurityAppName["IntegratorIo"] = "Integrator.io";
    DataSecurityAppName["Intercom"] = "Intercom";
    DataSecurityAppName["IntercombySalesforce"] = "Salesforce by Intercom";
    DataSecurityAppName["Ironclad"] = "Ironclad";
    DataSecurityAppName["Jira"] = "Jira Integration";
    DataSecurityAppName["JiraSalesforce"] = "Salesforce & JIRA Cloud Connector";
    DataSecurityAppName["Khoros"] = "Khoros";
    DataSecurityAppName["Kimble"] = "Kimble";
    DataSecurityAppName["KingswaySoft"] = "KingswaySoft/SSISIntegrationToolkit/";
    DataSecurityAppName["Kixie"] = "Kixie";
    DataSecurityAppName["LeanData"] = "LeanData";
    DataSecurityAppName["LeanDataOL"] = "LeanDataOL";
    DataSecurityAppName["LightningSharing"] = "LightningSharing";
    DataSecurityAppName["LinkedIn"] = "LinkedIn";
    DataSecurityAppName["LMSILT"] = "LMS ILT";
    DataSecurityAppName["LMSLearning"] = "LMS Learning";
    DataSecurityAppName["LookupHelper"] = "Lookup Helper";
    DataSecurityAppName["Loop"] = "Loop";
    DataSecurityAppName["Loopio"] = "Loopio";
    DataSecurityAppName["Lusha"] = "Lusha Push to Salesforce";
    DataSecurityAppName["Magentrix"] = "Magentrix";
    DataSecurityAppName["MagicMover"] = "Magic Mover";
    DataSecurityAppName["Mailchimp"] = "Mailchimp";
    DataSecurityAppName["MarketingCloud"] = "Marketing Cloud";
    DataSecurityAppName["Marketo"] = "Marketo";
    DataSecurityAppName["MarketoLeadMgm"] = "Marketo Lead Mgm";
    DataSecurityAppName["Maxio"] = "Maxio";
    DataSecurityAppName["MilestonesPM"] = "Milestones PM+";
    DataSecurityAppName["MissionControl"] = "Mission Control";
    DataSecurityAppName["Modigie"] = "Modigie";
    DataSecurityAppName["Monday"] = "Monday";
    DataSecurityAppName["MovableInk"] = "Movable Ink: Anubis Data Extractor";
    DataSecurityAppName["MSCI"] = "MSCI";
    DataSecurityAppName["MSCIEntitlements"] = "OneMSCI Entitlement Platform\u00A0";
    DataSecurityAppName["MSCIOne"] = "MSCI ONE API";
    DataSecurityAppName["MSCIIAM"] = "MSCI IAM API";
    DataSecurityAppName["MSCIApps"] = "MSCI Apps Permissioning";
    DataSecurityAppName["MSCIService"] = "MSCI API Service";
    DataSecurityAppName["MSCIAnalytics"] = "MSCI Analytics API Permissioning";
    DataSecurityAppName["MultiViewCalendar"] = "Multi-View Calendar";
    DataSecurityAppName["myViews"] = "myViews";
    DataSecurityAppName["NavigateButton"] = "NavigateButton";
    DataSecurityAppName["nCino"] = "nCino";
    DataSecurityAppName["NetSuiteConnector"] = "NetSuite Connector";
    DataSecurityAppName["NICEinContact"] = "NICE inContact";
    DataSecurityAppName["NPSP"] = "NPSP";
    DataSecurityAppName["Okta"] = "Okta";
    DataSecurityAppName["OktaImports"] = "Okta Imports";
    DataSecurityAppName["OktaWorkflows"] = "Okta Workflows";
    DataSecurityAppName["OneClickUpdate"] = "One Click Update";
    DataSecurityAppName["OneTrust"] = "OneTrust";
    DataSecurityAppName["OnRamp"] = "OnRamp v3";
    DataSecurityAppName["OrgChartPlus"] = "OrgChartPlus";
    DataSecurityAppName["Outreach"] = "Outreach";
    DataSecurityAppName["PagerDuty"] = "PagerDuty";
    DataSecurityAppName["PandaDoc"] = "PandaDoc";
    DataSecurityAppName["Pardot"] = "Pardot";
    DataSecurityAppName["PardotAPI"] = "Pardot API\u00A0";
    DataSecurityAppName["Pendo"] = "Pendo Salesforce Integration";
    DataSecurityAppName["PeopleAI"] = "PeopleAI";
    DataSecurityAppName["PowerBI"] = "PowerBI";
    DataSecurityAppName["Productiv"] = "Productiv";
    DataSecurityAppName["Propel"] = "Propel";
    DataSecurityAppName["Qualified"] = "Qualified";
    DataSecurityAppName["Qualtrics"] = "Qualtrics";
    DataSecurityAppName["RallySupportManager"] = "Rally Support Manager";
    DataSecurityAppName["Reachdesk"] = "Reachdesk";
    DataSecurityAppName["ReferenceEdge"] = "ReferenceEdge";
    DataSecurityAppName["Reveal"] = "Reveal Connect";
    DataSecurityAppName["RightRevRevRec"] = "RightRev Rev Rec";
    DataSecurityAppName["Rippling"] = "Rippling";
    DataSecurityAppName["RLAccess"] = "RL Access";
    DataSecurityAppName["RMKRViewCentral"] = "RMKR ViewCentral";
    DataSecurityAppName["RollupHelper"] = "Rollup Helper";
    DataSecurityAppName["RollupSummary"] = "Rollup Summary";
    DataSecurityAppName["S4G"] = "S4G";
    DataSecurityAppName["SalesforceBilling"] = "Salesforce Billing";
    DataSecurityAppName["SalesforceIQ"] = "SalesforceIQ";
    DataSecurityAppName["SalesforceIQInbox"] = "SalesforceIQ Inbox";
    DataSecurityAppName["SalesforceMaps"] = "Salesforce Maps";
    DataSecurityAppName["SalesHoodPlugin"] = "SalesHood Plugin";
    DataSecurityAppName["SalesInsights"] = "Sales Insights";
    DataSecurityAppName["Salesloft"] = "Salesloft";
    DataSecurityAppName["Scratchpad"] = "Scratchpad";
    DataSecurityAppName["SearchUnify"] = "SearchUnify";
    DataSecurityAppName["Seismic"] = "Seismic";
    DataSecurityAppName["SFAdminConApps"] = "SF Admin Con Apps";
    DataSecurityAppName["SFAgileAccelerator"] = "SF Agile Accelerator";
    DataSecurityAppName["SFChatterApps"] = "SF Chatter Apps";
    DataSecurityAppName["SFCommunities"] = "SF Communities";
    DataSecurityAppName["SFConnectedApps"] = "SF Connected Apps";
    DataSecurityAppName["SFDCChannelOrder"] = "SFDC Channel Order";
    DataSecurityAppName["Sifdata"] = "Sifdata";
    DataSecurityAppName["Simpplr"] = "Simpplr";
    DataSecurityAppName["SixSense"] = "6sense";
    DataSecurityAppName["SixSenseOauth"] = "sfdc_6sense_oauth_app";
    DataSecurityAppName["Skilljar"] = "Skilljar";
    DataSecurityAppName["Slack"] = "Slack";
    DataSecurityAppName["SNforSFDC"] = "SN for SFDC";
    DataSecurityAppName["Sonar"] = "Sonar";
    DataSecurityAppName["Squivr"] = "Squivr";
    DataSecurityAppName["SteelbrickCPQ"] = "Steelbrick CPQ";
    DataSecurityAppName["Superhuman"] = "Superhuman";
    DataSecurityAppName["SurveyMonkey"] = "SurveyMonkey for Salesforce";
    DataSecurityAppName["Tableau"] = "Tableau";
    DataSecurityAppName["TableauViz"] = "Tableau Viz";
    DataSecurityAppName["TableauDesktop"] = "Tableau Desktop";
    DataSecurityAppName["TableauOnline"] = "Tableau Online for Salesforce";
    DataSecurityAppName["TableauServer"] = "Tableau Server";
    DataSecurityAppName["Taskray"] = "Taskray";
    DataSecurityAppName["TechTargetPriority"] = "TechTarget Priority";
    DataSecurityAppName["TemplateVizArt"] = "Template VizArt";
    DataSecurityAppName["Terminus"] = "Terminus";
    DataSecurityAppName["TractionHierarchies"] = "Traction Hierarchies";
    DataSecurityAppName["Trailhead"] = "Trailhead";
    DataSecurityAppName["Trayio"] = "Tray.io";
    DataSecurityAppName["TrustRadius"] = "TrustRadius";
    DataSecurityAppName["Twitter"] = "Twitter";
    DataSecurityAppName["UIPath"] = "UIPath";
    DataSecurityAppName["UIPathProdVM1"] = "UIPath ProdVM1";
    DataSecurityAppName["UIPathProdVM2"] = "UIPath ProdVM2";
    DataSecurityAppName["UIPathProdVM3"] = "UIPath ProdVM3";
    DataSecurityAppName["UIPathProdVM4"] = "UIPath ProdVM4";
    DataSecurityAppName["UIPathProdVM5"] = "UIPath ProdVM5";
    DataSecurityAppName["UIPathProdVM6"] = "UIPath ProdVM6";
    DataSecurityAppName["UIPathProdVM7"] = "UIPath ProdVM7";
    DataSecurityAppName["UIPathProdVM8"] = "UIPath ProdVM8";
    DataSecurityAppName["UIPathTestVM1"] = "UIPath TestVM1";
    DataSecurityAppName["UniqueEntry"] = "Unique Entry";
    DataSecurityAppName["UserGems"] = "UserGems";
    DataSecurityAppName["Valooto"] = "Valooto";
    DataSecurityAppName["ValueCloud"] = "Value Cloud";
    DataSecurityAppName["Varonis"] = "Varonis";
    DataSecurityAppName["Vista"] = "Vista";
    DataSecurityAppName["VisualAntidote"] = "VisualAntidote";
    DataSecurityAppName["VivunInc"] = "Vivun Inc";
    DataSecurityAppName["Vonage"] = "Vonage";
    DataSecurityAppName["WebinfinityPRM"] = "Webinfinity PRM";
    DataSecurityAppName["Workato"] = "Workato";
    DataSecurityAppName["WorkatoConnector"] = "Workato connector";
    DataSecurityAppName["Xactly"] = "Exactly";
    DataSecurityAppName["XactlyExpress"] = "Xactly Express";
    DataSecurityAppName["zAgileConnect"] = "zAgileConnect";
    DataSecurityAppName["Zapier"] = "Zapier";
    DataSecurityAppName["Zendesk"] = "Zendesk";
    DataSecurityAppName["ZendeskIntegration"] = "Salesforce Integration for Zendesk";
    DataSecurityAppName["Zenkraft"] = "Zenkraft";
    DataSecurityAppName["ZenKraftBulk"] = "ZenKraft Bulk";
    DataSecurityAppName["ZigPR"] = "ZigPR";
    DataSecurityAppName["Zoom"] = "Zoom";
    DataSecurityAppName["ZoomInfo"] = "ZoomInfo";
    DataSecurityAppName["ZoomInfoIntegration"] = "ZoomInfo Integration";
    DataSecurityAppName["Zuora"] = "Zuora";
    DataSecurityAppName["Zuora360"] = "Zuora 360";
    DataSecurityAppName["ZuoraCRM"] = "Zuora CRM ID Provisioning Service";
    DataSecurityAppName["ZuoraQuotes"] = "Zuora Quotes";
    DataSecurityAppName["Zylo"] = "Zylo";
})(DataSecurityAppName || (exports.DataSecurityAppName = DataSecurityAppName = {}));
